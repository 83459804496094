import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import styled from '@emotion/styled'
import useStaticMetadata from '../hooks/useMetadata'

const awardsData = [
  {
    title: 'FICE /',
    description: 'Festival Iberoamericano de Creatividad y Estratégia',
    versions: [
      {
        title: '2015 /',
        description:
          'Plata en categoría Packaging / Cliente Coolechera Leche de Edición Especial',
        agency: 'AGENCIA / IKONOZÚ PUBLICIDAD',
      },
      {
        title: '2016 /',
        description:
          'Plata en categoría Packaging / Cliente Coolechera Leche de Edición Especial',
        agency: 'AGENCIA / IKONOZÚ PUBLICIDAD',
      },
    ],
  },
  {
    title: 'FEPI /',
    description: 'Festival de la Publicidad Independiente',
    versions: [
      {
        title: '2016 /',
        description:
          'Bronce en categoría Packaging / Cliente Hafen Cerveza Artesanal',
        agency: 'AGENCIA / IKONOZÚ PUBLICIDAD',
      },
      {
        title: '',
        description: '',
        agency: '',
      },
    ],
  },
  {
    title: 'Anuario',
    description: 'de Publicidad Colombiana',
    versions: [
      {
        title: '2015 /',
        description: 'Campaña de Apertura Nacional / Pricesmart Colombia',
        agency: '',
      },
      {
        title: '2016 /',
        description:
          'Bronce en categoría Packaging / Cliente Hafen Cerveza Artesanal',
        agency: 'AGENCIA / IKONOZÚ PUBLICIDAD',
      },
      {
        title: '2015 /',
        description: 'Diseño de identidad de Producto Coolfresco / Coolechera',
        agency: 'AGENCIA / IKONOZÚ PUBLICIDAD',
      },
      {
        title: '',
        description: '',
        agency: '',
      },
    ],
  },
  {
    title: 'Salón de Diseño',
    description: 'Universidad Autónoma del Caribe',
    versions: [
      {
        title: 'Edición 11 / 2008',
        description: 'Primer puesto  /  Proyecto Tipográfico del Semestre',
        agency: '',
      },
      {
        title: 'Edición 12 / 2009',
        description: 'Primer puesto  / Proyecto Editorial del Semestre',
        agency: '',
      },
    ],
  },
]

const AwardWrapper = styled.div`
  border-bottom: 1px solid #646464;
  padding: 0;
  margin: 0 0 32px;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    display: flex;
    padding: 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin: 48px 0 36px;
  }
`
const Awards = styled.div`
  margin: 100px 0 0;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 50px 0 0;
    ${AwardWrapper}:last-child {
      border: 0;
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin: 120px 0 0;
  }
`
const Body = styled.div`
  font-size: 1.385rem;
  line-height: 28px;
  margin: 0 0 25px;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    font-size: 0.938rem;
    max-width: 253px;
    flex-basis: 66%;
    margin-right: 33px;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: 1.25rem;
    max-width: 320px;
    line-height: 30px;
    margin: 0 82px 28px 0;
  }
`

const Title = styled.div`
  font-weight: bold;
`

const Versions = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1;
  }
`
const Version = styled.div`
  margin: 0 0 20px;
  line-height: 25px;
  h3 {
    font-size: 1.177rem;
    font-weight: 600;
  }
  p {
    font-size: 1rem;
    margin: 5px 0 0;
  }

  div {
    color: #9d9d9d;
    font-size: 0.692rem;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 15px 0 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex-basis: 48%;
    min-width: 220px;
    h3 {
      font-size: 0.85rem;
    }
    p {
      font-size: 0.75rem;
    }
    div {
      font-size: 0.5rem;
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    min-width: 320px;
    h3 {
      font-size: 1.063rem;
    }
    p {
      font-size: 0.938rem;
    }
    div {
      font-size: 0.625rem;
    }
  }
`

const Award = ({ title, description, versions }) => {
  return (
    <AwardWrapper>
      <Body>
        <Title>{title}</Title>
        <div>{description}</div>
      </Body>
      <Versions>
        {versions.map((item, idx) => (
          <Version key={idx}>
            <h3>{item.title}</h3>
            {item.description.split('/').map((item, idx) => (
              <p key={idx}>{`${item}${item && idx === 0 ? '/' : ''}`}</p>
            ))}
            <div>{item.agency}</div>
          </Version>
        ))}
      </Versions>
    </AwardWrapper>
  )
}

const Premios = ({ data }) => {
  const { premios } = useStaticMetadata()
  return (
    <Layout>
      <SEO
        title={premios.title}
        description={premios.description}
        image="/images/meta-premios.jpg"
      />
      <Container>
        <Awards>
          {awardsData.map((award, idx) => (
            <Award key={idx} {...award} />
          ))}
        </Awards>
      </Container>
    </Layout>
  )
}

export default Premios
